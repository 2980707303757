import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { Links, Meta, Outlet, Scripts, ScrollRestoration, ShouldRevalidateFunctionArgs, useNavigation, useRouteError, } from '@remix-run/react';
import { LinksFunction, LoaderFunctionArgs, redirect } from '@remix-run/node';
import NProgress from 'nprogress';
import nProgressStyles from 'nprogress/nprogress.css?url';

import './tailwind.css';
import { ReactNode, useEffect, useState } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { Error } from '~/components/error/Error';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { baseQueryClient } from '~/lib/react-query/configuration';
import { AuthService } from '~/services/AuthService';

export const links: LinksFunction = () => [
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  {
    rel: 'preconnect',
    href: 'https://fonts.gstatic.com',
    crossOrigin: 'anonymous',
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap',
  },
  {
    rel: 'stylesheet',
    href: nProgressStyles
  }
];

export async function loader({ request }: LoaderFunctionArgs) {
  console.log('root.tsx [33]');
  const session = await AuthService.getSession(request);
  if (!session && new URL(request.url).pathname !== '/login') {
    throw redirect('/login');
  }
  console.log('root.tsx [38]');
  return session;
}

export function shouldRevalidate({ formAction }: ShouldRevalidateFunctionArgs) {
  console.log('root.tsx [35]', formAction);
  return formAction && ['/login', '/logout'].includes(formAction);
}

export function Layout({ children }: { children: ReactNode }) {
  console.log('root.tsx [48] Render LAYOUT');
  return (
    <html lang="en" className="dark">
      <head>
        <meta charSet="utf-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <Meta/>
        <Links/>
      </head>
      <body>
        {children}
        <ScrollRestoration/>
        <Scripts/>
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <Error error={error}/>;
};

export default function App() {
  const [queryClient] = useState(() => baseQueryClient)
  const navigation = useNavigation();
  useEffect(() => {
    if (navigation.state === 'idle') NProgress.done();
    else NProgress.start();
  }, [navigation.state]);

  return <QueryClientProvider client={queryClient}>
    <Outlet/>
    <ReactQueryDevtools/>
  </QueryClientProvider>;
}
